/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom';
import Sellers from '../components/Sellers';
import Shops from '../components/Shops';
import Products from '../components/Products';
import Users from '../components/Users';
import { Dropdown } from 'flowbite-react';

const Home = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const [currentTabHome, setCurrentTabHome] = useState(localStorage.getItem('CurrentTabHome'));
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    return (
        <>
            <div className="app">
                <div className="header">
                    <div className="header-menu">
                        <Link className={`menu-link is-active ${props.Stores.filter(item => item.approved === false).length > 0 && 'notify'}`} to='/' >HOME</Link>
                        <Link className={`menu-link ${props.Users.length > 0 && props.Users?.filter(item => item.AdminPanel === true).filter(item => item.Permitted === false).filter(item => item.Blocked === false).length > 0 && 'notify'}`} to='/Manage' >MANAGE</Link>
                        <Link className="menu-link" to='/Settings' >SETTINGS</Link>
                    </div>
                    {/* <div className="search-bar">
                        <input type="text" placeholder="Search" />
                    </div> */}
                    <div className="header-profile">
                        {/* <div className="notification cursor-pointer">
                            {props.Stores.filter(item => item.approved === false).length > 0 && <span className="notification-number">{props.Stores.filter(item => item.approved === false).length}</span>}
                            s
                            <Dropdown label="Large dropdown" size="lg" dismissOnClick={false} renderTrigger={() =>
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-bell"
                                >
                                    <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0" />
                                </svg>
                            }>
                                {props.Stores.filter(item => item.approved === false).length > 0 ?
                                    <div className="UserNotifications h-full max-h-60 w-max p-2 flex justify-center items-center">
                                        {props.Stores.filter(item => item.approved === false).sort((a, b) => b.Time - a.Time).map((item, index) => (
                                            <div></div>
                                        ))}
                                    </div>
                                    :
                                    <>
                                        <div className="NoNotification h-max w-max p-5 flex justify-center items-center font-extrabold text-2xl uppercase">
                                            No New Notification
                                        </div>
                                    </>
                                }
                            </Dropdown>
                        </div> */}
                        {currentUser && <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" />}
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header bg-[var(--theme-bg-color)]">
                            <div className="header-menu menu-2 justify-evenly">
                                <span className={`main-header-link ${currentTabHome === 'Sellers' && 'is-active'} ${props.Stores.filter(item => item.approved === false).length > 0 && 'notify'}`} onClick={() => { localStorage.setItem('CurrentTabHome', 'Sellers'); setCurrentTabHome('Sellers') }}>SELLERS</span>
                                <span className={`main-header-link ${currentTabHome === 'Shops' && 'is-active'}`} onClick={() => { localStorage.setItem('CurrentTabHome', 'Shops'); setCurrentTabHome('Shops') }}>SHOPS</span>
                                <span className={`main-header-link ${currentTabHome === 'Products' && 'is-active'}`} onClick={() => { localStorage.setItem('CurrentTabHome', 'Products'); setCurrentTabHome('Products') }}>PRODUCTS</span>
                                <span className={`main-header-link ${currentTabHome === 'Users' && 'is-active'}`} onClick={() => { localStorage.setItem('CurrentTabHome', 'Users'); setCurrentTabHome('Users') }}>USERS</span>
                            </div>
                        </div>
                        <div className="content-wrapper">
                            {currentTabHome === 'Sellers' && <Sellers Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Category={props.Category} Users={props.Users} />}
                            {currentTabHome === 'Shops' && <Shops Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Category={props.Category} Users={props.Users} />}
                            {currentTabHome === 'Products' && <Products Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Category={props.Category} Users={props.Users} />}
                            {currentTabHome === 'Users' && <Users Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Category={props.Category} Users={props.Users} />}
                        </div>
                    </div>
                </div>
                <div className="overlay-app"></div>
            </div>
        </>
    )
}

export default Home