/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link } from 'react-router-dom';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const Admin = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    const ApprovePermission = async (val) => {
        await updateDoc(doc(db, 'Users', val.uid), {
            Permitted: true,
            Role: 'Manager',
        });
    }

    const DeclinePermission = async (val) => {
        await updateDoc(doc(db, 'Users', val.uid), {
            Blocked: true,
        });
    }

    return (
        <>
            {props.Users.length > 0 && props.Users?.filter(item => item.AdminPanel === true).filter(item => item.Permitted === false).filter(item => item.Blocked === false).length > 0 &&
                <>
                    <div className="content-section">
                        <div className="content-section-title text-xl font-semibold">New User Requests</div>
                        <ul>
                            {props.Users.length > 0 && props.Users?.filter(item => item.AdminPanel === true).filter(item => item.Permitted === false).filter(item => item.Blocked === false).map((item, index) => (
                                <li className="adobe-product flex flex-row justify-between" key={index}>
                                    <div className="products flex gap-2 cursor-pointer">
                                        <img src={item.photoURL} alt="PFP" className="h-10 w-10 rounded-full" />
                                        {bottomBarVisible <= 768 ? <span className="font-bold">{truncate(item.displayName, 10)}</span> : <span className="font-bold">{item.displayName}</span>}
                                    </div>
                                    {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Role === 'Admin' && <button className="content-button status-button open" onClick={() => ApprovePermission(item)}>ALLOW</button>}
                                    {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Role === 'Admin' && <button className="content-button status-button open" onClick={() => DeclinePermission(item)}>REMOVE</button>}
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            }
            <>
                <div className="content-section">
                    <div className="content-section-title text-xl font-semibold">Users</div>
                    <ul>
                        {props.Users.length > 0 && props.Users?.filter(item => item.AdminPanel === true).filter(item => item.Permitted === true).map((item, index) => (
                            <li className="adobe-product flex flex-row justify-between" key={index}>
                                <div className="products w-10 flex gap-2 cursor-pointer">
                                    <img src={item.photoURL} alt="PFP" className="h-10 w-10 rounded-full" />
                                    <span className="font-bold">{item.displayName}</span>
                                </div>
                                {bottomBarVisible > 768 &&
                                    <div className="products w-max cursor-pointer">
                                        <span className="font-bold">{item.EMail}</span>
                                    </div>
                                }
                                {bottomBarVisible > 768 &&
                                    <div className="products w-max cursor-pointer">
                                        <span className="font-bold">{item.Role}</span>
                                    </div>
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        </>
    )
}

export default Admin