/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox, FileInput, Label } from 'flowbite-react';
import { arrayRemove, arrayUnion, deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import swal from '@sweetalert/with-react';
import { TextField } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

const Categories = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const navigate = useNavigate();
    const [categoryName, setCategoryName] = useState('');
    const [categoryNameProduct, setCategoryNameProduct] = useState('');
    const [newCategoryNameProduct, setNewCategoryNameProduct] = useState('');
    const [categoriesRemoval, setCategoriesRemoval] = useState('');
    const [categoriesProductAdd, setCategoriesProductAdd] = useState('');
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedCategoriesRemoval, setSelectedCategoriesRemoval] = useState([]);
    const [file, setFile] = useState(null);

    const handleCheckboxChange = (cityName, isChecked) => {
        if (isChecked) {
            setSelectedCities(prevCities => [...prevCities, cityName]);
        } else {
            setSelectedCities(prevCities => prevCities.filter(city => city !== cityName));
        }
    };

    const handleCheckboxChangeRemove = (cityName, isChecked) => {
        if (isChecked) {
            setSelectedCategoriesRemoval(prevCities => [...prevCities, cityName]);
        } else {
            setSelectedCategoriesRemoval(prevCities => prevCities.filter(city => city !== cityName));
        }
    };

    useEffect(() => {
        setSelectedCategoriesRemoval([]);
    }, [categoriesRemoval])

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const handleChangeCategoryName = (event) => {
        setCategoryName(event.target.value);
    };

    const handleChangeCategoryNameProduct = (event) => {
        setCategoryNameProduct(event.target.value);
    };

    const handleChangeNewCategoryNameProduct = (event) => {
        setNewCategoryNameProduct(event.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleChangeCategoriesRemoval = (event) => {
        setCategoriesRemoval(event.target.value);
    };

    const handleChangeCategoriesProductAdd = (event) => {
        setCategoriesProductAdd(event.target.value);
    };

    const HandleAddCategory = async () => {
        const storageRef = ref(storage, "Categories/" + categoryName);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    await setDoc(doc(db, 'Categories', categoryName), {
                        Name: categoryName,
                        Image: downloadURL,
                        Items: arrayUnion(categoryNameProduct),
                    });
                    await setDoc(doc(db, 'Category', categoryName), {
                        Category: categoryName,
                        Products: [],
                    });
                    swal(`${categoryName} Added`, '', 'success')
                }
                );

            });
    }

    const HandleAddCategoryProduct = async () => {
        await updateDoc(doc(db, 'Categories', categoriesProductAdd), {
            Items: arrayUnion(newCategoryNameProduct),
        });
        swal(`${newCategoryNameProduct} Added To ${categoriesProductAdd}`, '', 'success')
    }

    const HandleRemoveCategories = async () => {
        selectedCategoriesRemoval.map(async (item) => (
            await updateDoc(doc(db, "Categories", categoriesRemoval), {
                Items: arrayRemove(item),
            })
        ));
        swal('Product Category Removed', '', 'success')
        setCategoriesRemoval('');
        setSelectedCategoriesRemoval([]);
    };


    useEffect(() => {
        props.Categories !== null && props.Categories?.map(async state => (
            await props.Categories?.filter((item, idx) => item.Name === state.Name)[0].Items.length === 0 &&
            await deleteDoc(doc(db, "Categories", state.Name))
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Categories])

    return (
        <>
            {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Role === 'Admin' ?
                <>
                    <>
                        <div className="HeadingTitle w-full mt-5 justify-center items-center text-center font-black text-2xl">
                            REMOVE CATEGORIES
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-start items-start rounded-lg">
                                <div class="app-card-sell">
                                    <div className="shopCategoryInput flex w-max justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">CATEGORIES</span>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: 'max-content' }}>
                                            <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR CATEGORY</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={categoriesRemoval}
                                                onChange={handleChangeCategoriesRemoval}
                                                label="CHOOSE YOUR CATEGORY"
                                                className='w-60'
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.Categories !== null && props.Categories.length > 0 && props.Categories?.sort((a, b) => a.Name.localeCompare(b.Name)).map(cat => (
                                                    <MenuItem value={cat.Name}>{cat.Name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {categoriesRemoval !== 'NONE' && categoriesRemoval !== '' &&
                                    <div class="app-card-sell w-full">
                                        <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                            <span className="w-full flex text-start font-extrabold text-xl">PRODUCT CATEGORIES</span>
                                            {props.Categories !== null && categoriesRemoval !== 'NONE' && categoriesRemoval !== '' && props.Categories.length > 0 && props.Categories?.filter((item, idx) => item.Name === categoriesRemoval)[0].Items.sort((a, b) => a.localeCompare(b)).map((Cat, index) => (
                                                <div className="flex flex-row justify-start w-full items-center gap-2" key={index}>
                                                    <Checkbox id={Cat} onChange={(e) => handleCheckboxChangeRemove(Cat, e.target.checked)} />
                                                    <Label htmlFor={Cat}>{Cat}</Label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex w-full justify-center items-center">
                            {selectedCategoriesRemoval.length > 0 ?
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-500 rounded-xl cursor-pointer" onClick={HandleRemoveCategories}>
                                    REMOVE CATEGORIES
                                </div>
                                :
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-300 rounded-xl cursor-default">
                                    REMOVE CATEGORIES
                                </div>
                            }
                        </div>
                    </>
                    <>
                        <div className="HeadingTitle w-full mt-10 justify-center items-center text-center font-black text-2xl">
                            ADD CATEGORIES
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-between items-start rounded-lg">
                                <div class="app-card-sell">
                                    <div className="ProductNameInput flex justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">Category Name</span>
                                        <TextField id="outlined-basic" label="Category Name" variant="filled" value={categoryName} onChange={handleChangeCategoryName} />
                                    </div>
                                </div>
                                <div class="app-card-sell">
                                    <div className="FileInput flex justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">Category Image</span>
                                        <Label
                                            htmlFor="dropzone-file"
                                            className="flex h-max w-72 pl-5 pr-5 pt-2 pb-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100"
                                        >
                                            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                    {file && file.name ? <span className="font-semibold">{file.name}</span> : <span className="font-semibold">Click to upload the Category Image</span>}
                                                </p>
                                            </div>
                                            <FileInput id="dropzone-file" className="hidden" onChange={handleFileChange} accept="image/jpg,image/jpeg,image/png" />
                                        </Label>
                                    </div>
                                </div>
                                <div class="app-card-sell">
                                    <div className="ProductNameInput flex justify-start items-start mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">Product Category Name</span>
                                        <TextField id="outlined-basic" label="Product Category Name" variant="filled" value={categoryNameProduct} onChange={handleChangeCategoryNameProduct} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center items-center">
                            {file !== null && categoryName !== '' && categoryNameProduct !== '' ?
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-500 rounded-xl cursor-pointer" onClick={HandleAddCategory}>
                                    ADD CATEGORY
                                </div>
                                :
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-300 rounded-xl cursor-default">
                                    ADD CATEGORY
                                </div>
                            }
                        </div>
                    </>
                    <>
                        <div className="HeadingTitle w-full mt-10 justify-center items-center text-center font-black text-2xl">
                            ADD PRODUCT CATEGORIES
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-between items-start rounded-lg">
                                <div class="app-card-sell">
                                    <div className="shopCategoryInput flex w-max justify-start items-start mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">CATEGORIES</span>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: 'max-content' }}>
                                            <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR CATEGORY</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={categoriesProductAdd}
                                                onChange={handleChangeCategoriesProductAdd}
                                                label="CHOOSE YOUR CATEGORY"
                                                className='w-60'
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.Categories !== null && props.Categories.length > 0 && props.Categories?.sort((a, b) => a.Name.localeCompare(b.Name)).map(cat => (
                                                    <MenuItem value={cat.Name}>{cat.Name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div class="app-card-sell">
                                    <div className="ProductNameInput flex justify-start items-start mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">Product Category Name</span>
                                        <TextField id="outlined-basic" label="Product Category Name" variant="filled" value={newCategoryNameProduct} onChange={handleChangeNewCategoryNameProduct} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center items-center">
                            {categoriesProductAdd !== '' && newCategoryNameProduct !== '' ?
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-500 rounded-xl cursor-pointer" onClick={HandleAddCategoryProduct}>
                                    ADD PRODUCT CATEGORY
                                </div>
                                :
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-300 rounded-xl cursor-default">
                                    ADD PRODUCT CATEGORY
                                </div>
                            }
                        </div>
                    </>
                </>
                :
                <>
                    <div className="h-full w-full flex justify-center items-center text-center text-2xl font-black">
                        YOU DON'T HAVE PERMISSION TO VIEW THIS SECTION
                    </div>
                </>
            }
        </>
    )
}

export default Categories