/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link } from 'react-router-dom';

const Users = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    return (
        <>
            <div className="content-section">
                <div className="content-section-title text-xl font-semibold">Users</div>
                <ul>
                    {props.Users.length > 0 && props.Users?.filter(item => item.AdminPanel === false).map((item, index) => (
                        <li className="adobe-product flex flex-row justify-between" key={index}>
                            <div className="products flex gap-2 cursor-pointer">
                                <img src={item.photoURL} alt="PFP" className="h-10 w-10 rounded-full" />
                                <span className="font-bold">{item.displayName}</span>
                            </div>
                            {bottomBarVisible > 768 &&
                                <div className="products w-max cursor-pointer flex justify-start">
                                    <span className="font-bold">{item.EMail}</span>
                                </div>
                            }
                            {bottomBarVisible > 768 &&
                                <div className="products w-max cursor-pointer flex justify-start">
                                    <span className="font-bold">{item.Status}</span>
                                </div>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default Users