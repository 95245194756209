/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox, FileInput, Label } from 'flowbite-react';
import { arrayRemove, arrayUnion, deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import swal from '@sweetalert/with-react';
import { TextField } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import moment from 'moment/moment';
import { v4 as uuid } from "uuid";
import ReactPlayer from 'react-player';

const Ads = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const navigate = useNavigate();
    const [filesBannerAds, setFilesBannerAds] = useState([]);
    const [multipleFilesBannerAds, setMultipleFilesBannerAds] = useState([]);
    const [progresspercentBannerAds, setProgressPercentBannerAds] = useState();
    // eslint-disable-next-line no-unused-vars
    const [errBannerAds, setErrBannerAds] = useState(false);
    const [loadingBannerAds, setLoadingBannerAds] = useState(false);
    const [shopChoosenBannerAds, setShopChoosenBannerAds] = useState('');
    const BannerAdsInputRef = useRef();
    const [shopChoosenVideoAds, setShopChoosenVideoAds] = useState('');
    const [fileVideoAds, setFileVideoAds] = useState(null);
    const [urlVideoAds, setUrlVideoAds] = useState(null);
    const [progresspercentVideoAds, setProgressPercentVideoAds] = useState();
    // eslint-disable-next-line no-unused-vars
    const [errVideoAds, setErrVideoAds] = useState(false);
    const [loadingVideoAds, setLoadingVideoAds] = useState(false);
    const VideoAdsInputRef = useRef();
    const [shopChoosenStoryAds, setShopChoosenStoryAds] = useState('');
    const [fileStoryAds, setFileStoryAds] = useState(null);
    const [urlStoryAds, setUrlStoryAds] = useState(null);
    const [progresspercentStoryAds, setProgressPercentStoryAds] = useState();
    // eslint-disable-next-line no-unused-vars
    const [errStoryAds, setErrStoryAds] = useState(false);
    const [loadingStoryAds, setLoadingStoryAds] = useState(false);
    const StoryAdsInputRef = useRef();

    const handleChangeShopChoosenBannerAds = (event) => {
        setShopChoosenBannerAds(event.target.value);
    };

    const handleChangeShopChoosenVideoAds = (event) => {
        setShopChoosenVideoAds(event.target.value);
    };

    const handleChangeShopChoosenStoryAds = (event) => {
        setShopChoosenStoryAds(event.target.value);
    };

    const HandleMultipleFileUploadBannerAds = (event) => {
        const filesBannerAds = Array.from(event.target.files);
        setFilesBannerAds(Array.from(event.target.files));
        const readers = filesBannerAds.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(readers).then((values) => {
            setMultipleFilesBannerAds(values);
        });
    }

    const SubmitBannerAds = () => {
        setLoadingBannerAds(true);
        const data = props.Stores.filter(item => item.approved === true).filter(item => item.storeName === shopChoosenBannerAds)[0]
        // eslint-disable-next-line array-callback-return
        filesBannerAds.map(file => {
            const adsID = uuid();
            const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
            const currentTimeStamp = moment().format('x');
            const storageRef = ref(storage, "Ads/" + adsID);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const roundedprogress = Math.round(progress);
                    setProgressPercentBannerAds(roundedprogress);
                },
                (error) => {
                    setLoadingBannerAds(false);
                    swal(error.code, '', 'error', {
                        buttons: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "bg-[#8c52ff]",
                                closeModal: true
                            }
                        }
                    });
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                        await updateDoc(doc(db, "Banner", "Ads"), {
                            Ads: arrayUnion({
                                adsID,
                                ad: downloadURL,
                                storeID: data.storeID,
                                storeState: data.storeState,
                                storeCity: data.storeCity,
                                TimeCreated: currentTime,
                                TimeStamp: currentTimeStamp,
                            }),
                        });
                    }
                    );
                })
        })
        setLoadingBannerAds(false);
        setFilesBannerAds([]);
        setMultipleFilesBannerAds([]);
        setShopChoosenBannerAds('');
        BannerAdsInputRef.current.value = null;
        swal('Banner Ads Added!!', '', 'success', {
            buttons: {
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "bg-[#8c52ff]",
                    closeModal: true
                }
            }
        });
    }

    const HandleFileChangeVideoAds = (event) => {
        setFileVideoAds(event.target.files[0]);
        setUrlVideoAds(URL.createObjectURL(event.target.files[0]));
    }

    const SubmitVideoAds = async () => {
        setLoadingVideoAds(true);
        const data = props.Stores.filter(item => item.approved === true).filter(item => item.storeName === shopChoosenVideoAds)[0]
        const videoID = uuid();
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        const currentTimeStamp = moment().format('x');
        const storageRef = ref(storage, "Video/" + videoID);
        const uploadTask = uploadBytesResumable(storageRef, fileVideoAds);
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const roundedprogress = Math.round(progress);
                setProgressPercentVideoAds(roundedprogress);
            },
            (error) => {
                setLoadingVideoAds(false);
                swal(error.code, '', 'error', {
                    buttons: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "bg-[#8c52ff]",
                            closeModal: true
                        }
                    }
                });
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    await setDoc(doc(db, "Video", videoID), {
                        videoID,
                        ad: downloadURL,
                        storeID: data.storeID,
                        storeState: data.storeState,
                        storeCity: data.storeCity,
                        TimeCreated: currentTime,
                        TimeStamp: currentTimeStamp,
                        Likes: [],
                        LikesCount: 0,
                        Comments: [],
                        CommentCount: 0,
                        Store: data,
                    });
                });
            });
        setLoadingVideoAds(false);
        setFileVideoAds(null);
        setUrlVideoAds(null);
        setShopChoosenVideoAds('');
        VideoAdsInputRef.current.value = null;
        swal('Video Ad Uploaded!!', '', 'success', {
            buttons: {
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "bg-[#8c52ff]",
                    closeModal: true
                }
            }
        });
    }

    const HandleFileChangeStoryAds = (event) => {
        setFileStoryAds(event.target.files[0]);
        setUrlStoryAds(URL.createObjectURL(event.target.files[0]));
    }

    const SubmitStoryAds = async () => {
        setLoadingStoryAds(true);
        const data = props.Stores.filter(item => item.approved === true).filter(item => item.storeName === shopChoosenStoryAds)[0]
        const storyID = uuid();
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        const currentTimeStamp = moment().format('x');
        const storageRef = ref(storage, "Story/" + storyID);
        const uploadTask = uploadBytesResumable(storageRef, fileStoryAds);
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const roundedprogress = Math.round(progress);
                setProgressPercentStoryAds(roundedprogress);
            },
            (error) => {
                setLoadingStoryAds(false);
                swal(error.code, '', 'error', {
                    buttons: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "bg-[#8c52ff]",
                            closeModal: true
                        }
                    }
                });
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    await setDoc(doc(db, "Story", storyID), {
                        storyID,
                        ad: downloadURL,
                        storeID: data.storeID,
                        storeState: data.storeState,
                        storeCity: data.storeCity,
                        TimeCreated: currentTime,
                        TimeStamp: currentTimeStamp,
                        Likes: [],
                        LikesCount: 0,
                        Comments: [],
                        CommentCount: 0,
                        Store: data,
                    });
                }
                );
            })
        setLoadingStoryAds(false);
        setFileStoryAds(null);
        setUrlStoryAds(null);
        setShopChoosenStoryAds('');
        StoryAdsInputRef.current.value = null;
        swal('Story Ad Uploaded!!', '', 'success', {
            buttons: {
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "bg-[#8c52ff]",
                    closeModal: true
                }
            }
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Role === 'Admin' ?
                <>
                    <>
                        <div className="HeadingTitle w-full mt-5 justify-center items-center text-center font-black text-2xl">
                            BANNER ADS
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-evenly items-center rounded-lg">
                                <div class="app-card-sell w-80">
                                    <div className="shopCategoryInput flex w-80 justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">SHOPS</span>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">CHOOSE A SHOP</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={shopChoosenBannerAds}
                                                onChange={handleChangeShopChoosenBannerAds}
                                                label="CHOOSE A SHOP"
                                                className='w-80'
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.Stores.filter(item => item.approved === true).length > 0 && props.Shops?.sort((a, b) => a.storeName.localeCompare(b.storeName)).map(shop => (
                                                    <MenuItem value={shop.storeName} className="flex w-full flex-row justify-center items-center">
                                                        <img src={shop.logo} alt="Shop Logo" className="h-10 w-10 rounded-full" />
                                                        <span className="text-xl font-bold ml-5">{shop.storeName}</span>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {shopChoosenBannerAds !== '' && shopChoosenBannerAds.length > 0 &&
                                    <div class="app-card-sell w-80">
                                        <div className="bannerAdsSection">
                                            <div>
                                                <span className="w-full flex text-start font-extrabold text-xl uppercase">Upload multiple Banner Ads</span>
                                            </div>
                                            <FileInput id="multiple-file-upload" accept="image/jpg,image/jpeg,image/png" multiple className='mt-2 w-80' onChange={HandleMultipleFileUploadBannerAds} ref={BannerAdsInputRef} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="content-section mt-5">
                            <div class="apps-card gap-10">
                                {multipleFilesBannerAds.map(file => (
                                    <img src={file} alt="" className='h-40 w-40 rounded-2xl' />
                                ))}
                            </div>
                        </div>
                        <div class="content-section mt-5">
                            <div className="SubmitBannerAdsBtn flex justify-center items-center w-full">
                                {filesBannerAds.length > 0 ?
                                    <>
                                        {loadingBannerAds === false ?
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-500 text-white text-2xl font-extrabold rounded-xl cursor-pointer" onClick={SubmitBannerAds}>
                                                UPLOAD BANNER ADS
                                            </div>
                                            :
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                                UPLOADING BANNER ADS
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                        UPLOAD BANNER ADS
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    <>
                        <div className="HeadingTitle w-full mt-5 justify-center items-center text-center font-black text-2xl">
                            VIDEO ADS
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-evenly items-center rounded-lg">
                                <div class="app-card-sell w-80">
                                    <div className="shopCategoryInput flex w-80 justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">SHOPS</span>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">CHOOSE A SHOP</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={shopChoosenVideoAds}
                                                onChange={handleChangeShopChoosenVideoAds}
                                                label="CHOOSE A SHOP"
                                                className='w-80'
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.Stores.filter(item => item.approved === true).length > 0 && props.Shops?.sort((a, b) => a.storeName.localeCompare(b.storeName)).map(shop => (
                                                    <MenuItem value={shop.storeName} className="flex w-full flex-row justify-center items-center">
                                                        <img src={shop.logo} alt="Shop Logo" className="h-10 w-10 rounded-full" />
                                                        <span className="text-xl font-bold ml-5">{shop.storeName}</span>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {shopChoosenVideoAds !== '' && shopChoosenVideoAds.length > 0 &&
                                    <div class="app-card-sell w-80">
                                        <div className="bannerAdsSection">
                                            <div>
                                                <Label htmlFor="multiple-file-upload" value="Upload Video Ad" className="uppercase text-xl" />
                                            </div>
                                            <FileInput id="multiple-file-upload" accept="video/*" className='mt-2' onChange={HandleFileChangeVideoAds} ref={VideoAdsInputRef} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="content-section-video mt-5">
                            <div class="apps-card-video flex ju items-center w-full">
                                {urlVideoAds !== null && <ReactPlayer url={urlVideoAds} controls playing={true} loop={true} width='max-content' height='500px' />}
                            </div>
                        </div>
                        <div class="content-section mt-5">
                            <div className="SubmitBannerAdsBtn flex justify-center items-center w-full">
                                {fileVideoAds !== null ?
                                    <>
                                        {loadingVideoAds === false ?
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-500 text-white text-2xl font-extrabold rounded-xl cursor-pointer" onClick={SubmitVideoAds}>
                                                UPLOAD VIDEO AD
                                            </div>
                                            :
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                                UPLOADING VIDEO AD
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                        UPLOAD VIDEO AD
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    <>
                        <div className="HeadingTitle w-full mt-5 justify-center items-center text-center font-black text-2xl">
                            STORY ADS
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-evenly items-center rounded-lg">
                                <div class="app-card-sell w-80">
                                    <div className="shopCategoryInput flex w-80 justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">SHOPS</span>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">CHOOSE A SHOP</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={shopChoosenStoryAds}
                                                onChange={handleChangeShopChoosenStoryAds}
                                                label="CHOOSE A SHOP"
                                                className='w-80'
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.Stores.filter(item => item.approved === true).length > 0 && props.Shops?.sort((a, b) => a.storeName.localeCompare(b.storeName)).map(shop => (
                                                    <MenuItem value={shop.storeName} className="flex w-full flex-row justify-center items-center">
                                                        <img src={shop.logo} alt="Shop Logo" className="h-10 w-10 rounded-full" />
                                                        <span className="text-xl font-bold ml-5">{shop.storeName}</span>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {shopChoosenStoryAds !== '' && shopChoosenStoryAds.length > 0 &&
                                    <div class="app-card-sell w-80">
                                        <div className="bannerAdsSection">
                                            <div>
                                                <Label htmlFor="multiple-file-upload" value="Upload Story Ad" className="uppercase text-xl" />
                                            </div>
                                            <FileInput id="multiple-file-upload" accept="video/*" className='mt-2' onChange={HandleFileChangeStoryAds} ref={StoryAdsInputRef} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div class="content-section-video mt-5">
                            <div class="apps-card-video flex ju items-center w-full">
                                {urlStoryAds !== null && <ReactPlayer url={urlStoryAds} controls playing={true} loop={true} width='max-content' height='500px' />}
                            </div>
                        </div>
                        <div class="content-section mt-5">
                            <div className="SubmitBannerAdsBtn flex justify-center items-center w-full">
                                {fileStoryAds !== null ?
                                    <>
                                        {loadingStoryAds === false ?
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-500 text-white text-2xl font-extrabold rounded-xl cursor-pointer" onClick={SubmitStoryAds}>
                                                UPLOAD STORY AD
                                            </div>
                                            :
                                            <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                                UPLOADING STORY AD
                                            </div>
                                        }
                                    </>
                                    :
                                    <div className="SubmitBTNAds w-max pl-5 pr-5 pt-2 pb-2 bg-blue-300 text-white text-2xl font-extrabold rounded-xl cursor-default">
                                        UPLOAD STORY AD
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                </>
                :
                <>
                    <div className="h-full w-full flex justify-center items-center text-center text-2xl font-black">
                        YOU DON'T HAVE PERMISSION TO VIEW THIS SECTION
                    </div>
                </>
            }
        </>
    )
}

export default Ads