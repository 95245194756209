/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox, Label } from 'flowbite-react';
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import swal from '@sweetalert/with-react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Cities = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const navigate = useNavigate();
    const [storeState, setStoreState] = useState('');
    const [storeStateRemoval, setStoreStateRemoval] = useState('');
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedCitiesRemoval, setSelectedCitiesRemoval] = useState([]);

    const handleCheckboxChange = (cityName, isChecked) => {
        if (isChecked) {
            setSelectedCities(prevCities => [...prevCities, cityName]);
        } else {
            setSelectedCities(prevCities => prevCities.filter(city => city !== cityName));
        }
    };

    const handleCheckboxChangeRemove = (cityName, isChecked) => {
        if (isChecked) {
            setSelectedCitiesRemoval(prevCities => [...prevCities, cityName]);
        } else {
            setSelectedCitiesRemoval(prevCities => prevCities.filter(city => city !== cityName));
        }
    };

    useEffect(() => {
        setSelectedCities([]);
    }, [storeState])

    useEffect(() => {
        setSelectedCitiesRemoval([]);
    }, [storeStateRemoval])

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const handleChangeStoreState = (event) => {
        setStoreState(event.target.value);
    };

    const handleChangeStoreStateRemoval = (event) => {
        setStoreStateRemoval(event.target.value);
    };

    const HandleAddCities = async () => {
        selectedCities.map(async (item) => (
            await updateDoc(doc(db, "ApprovedLocations", "Locations"), {
                Cities: arrayUnion(item),
            })
        ));
        await updateDoc(doc(db, "ApprovedLocations", "Locations"), {
            States: arrayUnion(storeState),
        });
        swal('Cities added', '', 'success')
        setStoreState('');
        setSelectedCities([]);
    }

    const HandleRemoveCities = async () => {
        selectedCitiesRemoval.map(async (item) => (
            await updateDoc(doc(db, "ApprovedLocations", "Locations"), {
                Cities: arrayRemove(item),
            })
        ));
        swal('Cities removed', '', 'success')
        setStoreStateRemoval('');
        setSelectedCitiesRemoval([]);
    }

    useEffect(() => {
        props.Locations !== null && props.Locations.States.map(async state => (
            await props.Locations.Cities?.filter((item, idx) => item.state_name === state).length === 0 &&
            await updateDoc(doc(db, "ApprovedLocations", "Locations"), {
                States: arrayRemove(state),
            })
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.Locations])

    return (
        <>
            {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Role === 'Admin' ?
                <>
                    <>
                        <div className="HeadingTitle w-full mt-5 justify-center items-center text-center font-black text-2xl">
                            REMOVE CITIES
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-evenly items-start rounded-lg">
                                <div class="app-card-sell w-full">
                                    <div className="shopCategoryInput flex w-full justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">STATE</span>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR STATE</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={storeStateRemoval}
                                                onChange={handleChangeStoreStateRemoval}
                                                label="CHOOSE YOUR STATE"
                                                className='w-full'
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.Locations !== null && props.Locations.States.length > 0 && props.Locations.States?.sort((a, b) => a.localeCompare(b)).map(state => (
                                                    <MenuItem value={state}>{state}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {storeStateRemoval !== '' &&
                                    <div class="app-card-sell w-full">
                                        <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                            <span className="w-full flex text-start font-extrabold text-xl">CITY</span>
                                            {props.Locations !== null && storeStateRemoval !== '' && props.Locations.Cities.length > 0 && props.Locations.Cities?.filter((item, idx) => item.state_name === storeStateRemoval).sort((a, b) => a.name.localeCompare(b.name)).map(city => (
                                                <div className="flex flex-row justify-start w-full items-center gap-2" key={city.name}>
                                                    <Checkbox id={city.name} onChange={(e) => handleCheckboxChangeRemove(city, e.target.checked)} />
                                                    <Label htmlFor={city.name}>{city.name}</Label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex w-full justify-center items-center">
                            {selectedCitiesRemoval.length > 0 ?
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-500 rounded-xl cursor-pointer" onClick={HandleRemoveCities}>
                                    REMOVE
                                </div>
                                :
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-300 rounded-xl cursor-default">
                                    REMOVE
                                </div>
                            }
                        </div>
                    </>
                    <>
                        <div className="HeadingTitle w-full mt-10 justify-center items-center text-center font-black text-2xl">
                            ADD CITIES
                        </div>
                        <div className="content-section-new flex flex-col h-max min-w-[95%]">
                            <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-evenly items-start rounded-lg">
                                <div class="app-card-sell w-full">
                                    <div className="shopCategoryInput flex w-full justify-start items-center mt-5 flex-col gap-2">
                                        <span className="w-full flex text-start font-extrabold text-xl">STATE</span>
                                        <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR STATE</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={storeState}
                                                onChange={handleChangeStoreState}
                                                label="CHOOSE YOUR STATE"
                                                className='w-full'
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {props.States.length > 0 && props.States?.sort((a, b) => a.localeCompare(b)).map(state => (
                                                    <MenuItem value={state}>{state}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {storeState !== 'NONE' && storeState !== '' &&
                                    <div class="app-card-sell w-full">
                                        <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                            <span className="w-full flex text-start font-extrabold text-xl">CITY</span>
                                            {storeState !== 'NONE' && storeState !== '' && props.Cities.length > 0 && props.Cities?.filter((item, idx) => item.state_name === storeState).sort((a, b) => a.name.localeCompare(b.name)).map(city => (
                                                <div className="flex flex-row justify-start w-full items-center gap-2" key={city.name}>
                                                    <Checkbox id={city.name} onChange={(e) => handleCheckboxChange(city, e.target.checked)} />
                                                    <Label htmlFor={city.name}>{city.name}</Label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex w-full justify-center items-center">
                            {selectedCities.length > 0 ?
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-500 rounded-xl cursor-pointer" onClick={HandleAddCities}>
                                    ADD
                                </div>
                                :
                                <div className="HeadingTitle w-max pl-5 pr-5 pt-2 pb-2 mt-5 justify-center items-center text-center font-black text-2xl text-white bg-blue-300 rounded-xl cursor-default">
                                    ADD
                                </div>
                            }
                        </div>
                    </>
                </>
                :
                <>
                    <div className="h-full w-full flex justify-center items-center text-center text-2xl font-black">
                        YOU DON'T HAVE PERMISSION TO VIEW THIS SECTION
                    </div>
                </>
            }
        </>
    )
}

export default Cities