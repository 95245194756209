/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Products = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const navigate = useNavigate();
    const [storeState, setStoreState] = useState('');
    const [storeCity, setStoreCity] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const handleChangeStoreState = (event) => {
        setStoreState(event.target.value);
    };

    const handleChangeStoreCity = (event) => {
        setStoreCity(event.target.value);
    };

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    return (
        <>
            {props.Category.length > 0 && props.Category.filter(item => item.Products.length > 0).length > 0 ?
                <div className="content-section-new flex flex-col mt-5 h-max min-w-[95%]">
                    <div className="content-section-title text-xl font-medium">Filter</div>
                    <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-evenly items-center rounded-lg bg-[var(--theme-bg-color)]">
                        <div class="app-card-sell">
                            <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                <span className="w-full flex text-start">STATE</span>
                                <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR STATE</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={storeState}
                                        onChange={handleChangeStoreState}
                                        label="CHOOSE YOUR STATE"
                                        className='w-60'
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {props.States.length > 0 && props.States?.sort((a, b) => a.localeCompare(b)).map(city => (
                                            <MenuItem value={city}>{city}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div class="app-card-sell">
                            <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                <span className="w-full flex text-start">CITY</span>
                                <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR CITY</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={storeCity}
                                        onChange={handleChangeStoreCity}
                                        label="CHOOSE YOUR CITY"
                                        className='w-60'
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {storeState !== '' && props.Cities.length > 0 && props.Cities?.filter((item, idx) => item.state_name === storeState).sort((a, b) => a.name.localeCompare(b.name)).map(city => (
                                            <MenuItem value={city.name}>{city.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="content-section-title text-xl font-semibold mt-10">Products</div>
                    {storeCity !== '' ?
                        <>
                            <div className="content-section">
                                {props.Category.length > 0 && props.Category.filter(item => item.Products.length > 0).sort((a, b) => a.Category.localeCompare(b.Category)).map(cat => (
                                    <>
                                        <div className="NameAndViewAll h-max w-full flex flex-row justify-between items-center">
                                            <div className="content-section-title-second flex cursor-default text-center justify-start items-center">
                                                <span className='pt-2 pb-2 pl-5 pr-5 cursor-default'>{cat.Category}</span>
                                            </div>
                                        </div>
                                        {cat.Products.filter(item => item.storeCity === storeCity).length > 0 ?
                                            <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                                {cat.Products.filter(item => item.storeCity === storeCity).filter((item, index) => (index <= 100000)).reverse().map(catProducts => (
                                                    <div className={`catCard ${bottomBarVisible <= 360 ? 'h-40 w-36' : 'h-44 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`}>
                                                        <div className="h-36 w-full flex justify-center items-center">
                                                            <img src={catProducts.productImage[0]} alt={catProducts.productName} className="h-32 w-32 rounded-2xl" />
                                                        </div>
                                                        <div className="categoryName text-base font-extrabold">{truncate(catProducts.productName, 15)}</div>
                                                    </div>
                                                ))}
                                                {cat.Products.length % 2 !== 0 &&
                                                    <div className={`catCard ${bottomBarVisible <= 360 ? 'h-40 w-36' : 'h-44 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                        <div className="h-36 w-full flex justify-center items-center">
                                                            <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                        </div>
                                                        <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <>
                                                <div className="w-full h-full justify-center items-center text-center text-xl font-bold">
                                                    NO PRODUCTS FOUND IN {storeCity}
                                                </div>
                                            </>
                                        }
                                    </>
                                ))}
                            </div>
                        </>
                        :
                        <div className="content-section">
                            {props.Category.length > 0 && props.Category.filter(item => item.Products.length > 0).sort((a, b) => a.Category.localeCompare(b.Category)).map(cat => (
                                <>
                                    <div className="NameAndViewAll h-max w-full flex flex-row justify-between items-center">
                                        <div className="content-section-title-second flex cursor-default text-center justify-start items-center">
                                            <span className='pt-2 pb-2 pl-5 pr-5 cursor-default'>{cat.Category}</span>
                                        </div>
                                    </div>
                                    <div className={`apps-card flex gap-2 ${bottomBarVisible <= 730 && 'justify-evenly'}`}>
                                        {cat.Products.filter((item, index) => (index <= 100000)).reverse().map(catProducts => (
                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-40 w-36' : 'h-44 w-40'} cursor-pointer flex flex-col gap-2 justify-center items-center p-2 rounded-md`}>
                                                <div className="h-36 w-full flex justify-center items-center">
                                                    <img src={catProducts.productImage[0]} alt={catProducts.productName} className="h-32 w-32 rounded-2xl" />
                                                </div>
                                                <div className="categoryName text-base font-extrabold">{truncate(catProducts.productName, 15)}</div>
                                            </div>
                                        ))}
                                        {cat.Products.length % 2 !== 0 &&
                                            <div className={`catCard ${bottomBarVisible <= 360 ? 'h-40 w-36' : 'h-44 w-40'} flex flex-col gap-2 justify-center items-center p-2 rounded-md`} style={{ visibility: 'hidden' }}>
                                                <div className="h-36 w-full flex justify-center items-center">
                                                    <img src="" alt="" className="h-32 w-32 rounded-2xl" />
                                                </div>
                                                <div className="categoryName text-lg font-extrabold">lorem ipsum</div>
                                            </div>
                                        }
                                    </div>
                                </>
                            ))}
                        </div>
                    }
                </div>
                :
                <div className='w-full h-full flex justify-center items-center text-center text-2xl font-black'>
                    NO PRODUCTS FOUND!
                </div>
            }
        </>
    )
}

export default Products