/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import TopBarProgress from "react-topbar-progress-indicator";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate
} from "react-router-dom";
import { AuthContext } from './context/AuthContext';
import Home from './pages/Home';
import Auth from './pages/Auth';
import Manage from './pages/Manage';
import Settings from './pages/Settings';
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from './firebase';
import PendingPermission from './pages/PendingPermission';

const CustomSwitch = ({ children }) => {
  const [progress, setProgress] = useState(false)
  const [prevLoc, setPrevLoc] = useState("")
  const location = useLocation()

  useEffect(() => {
    // gsap.fromTo(".app", { opacity: 0 }, { opacity: 1, duration: 2 });
    setPrevLoc(location.pathname)
    setProgress(true)
    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }
  }, [location])

  useEffect(() => {
    setProgress(false)
  }, [prevLoc])

  return (
    <>
      {progress && <TopBarProgress />}
      <Routes>{children}</Routes>
    </>
  )
}

function App() {
  const { currentUser } = useContext(AuthContext);
  const [storesData, setStoresData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [shopsData, setShopsData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [locationsData, setLocationsData] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [categoriesData, setCategoriesData] = useState(null);

  const GetStates = () => {
    const unsub = onSnapshot(doc(db, "States", "States"), (doc) => {
      setStatesData(doc.data().States);
    });

    return () => {
      unsub();
    };
  }

  const GetCities = () => {
    const unsub = onSnapshot(doc(db, "Cities", "Cities"), (doc) => {
      setCitiesData(doc.data().Cities);
    });

    return () => {
      unsub();
    };
  }

  const GetLocations = () => {
    const unsub = onSnapshot(doc(db, "ApprovedLocations", "Locations"), (doc) => {
      setLocationsData(doc.data());
    });

    return () => {
      unsub();
    };
  }

  const GetStoresData = () => {
    const q = query(collection(db, "Stores"));
    onSnapshot(q, (querySnapshot) => {
      const stores = [];
      querySnapshot.forEach((doc) => {
        stores.push(doc.data());
      });
      setStoresData(stores);
    });
  }

  const GetUsersData = () => {
    const q = query(collection(db, "Users"));
    onSnapshot(q, (querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      setUsersData(users);
    });
  }

  const GetShopsData = () => {
    const unsub = onSnapshot(doc(db, "Shops", "Stores"), (doc) => {
      setShopsData(doc.data().Shops);
    });

    return () => {
      unsub();
    };
  }

  const GetCategoriesData = () => {
    const q = query(collection(db, "Categories"));
    onSnapshot(q, (querySnapshot) => {
      const cats = [];
      querySnapshot.forEach((doc) => {
        cats.push(doc.data());
      });
      setCategoriesData(cats);
    });
  }


  const GetCategoryData = () => {
    const q = query(collection(db, "Category"));
    onSnapshot(q, (querySnapshot) => {
      const cat = [];
      querySnapshot.forEach((doc) => {
        cat.push(doc.data());
      });
      setCategoryData(cat);
    });
  }

  useEffect(() => {
    GetStates();
    GetCities();
    GetLocations();
    GetStoresData();
    GetUsersData();
    GetShopsData();
    GetCategoryData();
    GetCategoriesData();
  }, [])

  const ProtectedRouteHome = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/Auth" />;
    } else if (currentUser) {
      if (usersData.length > 0 && usersData?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Permitted === false) {
        return <Navigate to="/PendingPermission" />;
      }
    }
    return children
  };

  const ProtectedRoutePermission = ({ children }) => {
    if (currentUser) {
      if (usersData.length > 0 && usersData?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Permitted === true) {
        return <Navigate to="/" />;
      }
    }
    return children
  };

  TopBarProgress.config({
    barThickness: 5,
    barColors: {
      "0": "#ff0058",
      "0.5": "#ffbc00",
      "1.0": "#03a9f4",
    },
  });

  useEffect(() => {
    localStorage.getItem('CurrentTabHome') === null && localStorage.setItem('CurrentTabHome', 'Sellers')
    localStorage.getItem('CurrentTabManage') === null && localStorage.setItem('CurrentTabManage', 'Cities')
    localStorage.getItem('CurrentTabSettings') === null && localStorage.setItem('CurrentTabSettings', 'Sellers')
  }, []);

  return (
    <Router basename={"/"}>
      <CustomSwitch>
        <Route exact path="/" element={<ProtectedRouteHome><Home Stores={storesData} Shops={shopsData} States={statesData} Cities={citiesData} Locations={locationsData} Category={categoryData} Categories={categoriesData} Users={usersData} /></ProtectedRouteHome>} />
        <Route path="/Manage" element={<ProtectedRouteHome><Manage Stores={storesData} Shops={shopsData} States={statesData} Cities={citiesData} Locations={locationsData} Category={categoryData} Categories={categoriesData} Users={usersData} /></ProtectedRouteHome>} />
        <Route path="/Settings" element={<ProtectedRouteHome><Settings Stores={storesData} Shops={shopsData} States={statesData} Cities={citiesData} Locations={locationsData} Category={categoryData} Categories={categoriesData} Users={usersData} /></ProtectedRouteHome>} />
        <Route path="/Auth" element={<Auth Stores={storesData} Shops={shopsData} States={statesData} Cities={citiesData} Locations={locationsData} Category={categoryData} Categories={categoriesData} Users={usersData} />} />
        <Route path="/PendingPermission" element={<ProtectedRoutePermission><PendingPermission Stores={storesData} Shops={shopsData} States={statesData} Cities={citiesData} Locations={locationsData} Category={categoryData} Categories={categoriesData} Users={usersData} /></ProtectedRoutePermission>} />
      </CustomSwitch>
    </Router>
  );
}

export default App;
