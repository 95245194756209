/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link } from 'react-router-dom';
import { arrayRemove, arrayUnion, deleteDoc, doc, increment, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import swal from '@sweetalert/with-react';

const Sellers = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const ApproveSeller = async (val) => {
        await updateDoc(doc(db, "Stores", val.storeID), {
            approved: true
        });
        await updateDoc(doc(db, "Shops", 'Stores'), {
            Shops: arrayUnion({
                storeID: val.storeID,
                owner: val.owner,
                ownerID: val.ownerID,
                ownerPFP: val.ownerPFP,
                number: val.number,
                storeName: val.storeName,
                logo: val.logo,
                address: val.address,
                storeState: val.storeState,
                storeCity: val.storeCity,
                gst: val.gst,
                category: val.category,
                TimeCreated: val.TimeCreated,
                TimeStamp: val.TimeStamp,
                Products: val.Products[0],
            })
        });
        await updateDoc(doc(db, "Products", 'Products'), {
            Products: arrayUnion(val.Products[0])
        });
        await updateDoc(doc(db, "Category", val.category), {
            Products: arrayUnion(val.Products[0])
        });
        await updateDoc(doc(db, "Users", val.ownerID), {
            isApproved: true,
            StoreAdded: true,
            storeID: val.storeID,
            storeState: val.storeState,
            storeCity: val.storeCity,
            storeCategory: val.category,
        });
    }

    const DeclineSeller = async (val) => {
        await deleteDoc(doc(db, "Stores", val.storeID));
        await updateDoc(doc(db, "Users", currentUser.uid), {
            StoreAdded: false,
            isApproved: false,
        });
    }

    // const RemoveSeller = async (val) => {
    //     swal({
    //         title: "Delete Account",
    //         text: "Are You Sure?",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     }).then(async (willDelete) => {
    //         if (willDelete) {
    //             props.Category?.filter(item => item.Category === val.category)[0].Products.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
    //                 await updateDoc(doc(db, "Category", val.category), {
    //                     Products: arrayRemove(item2),
    //                 })
    //             ));
    //             props.UserData !== null && props.UserData.StoreAdded === true && props.Products?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
    //                 await updateDoc(doc(db, "Products", "Products"), {
    //                     Products: arrayRemove(item2),
    //                 })
    //             ));
    //             props.UserData !== null && props.UserData.StoreAdded === true && props.Shops?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
    //                 await updateDoc(doc(db, "Shops", "Stores"), {
    //                     Shops: arrayRemove(item2),
    //                 })
    //             ));
    //             props.UserData !== null && props.UserData.StoreAdded === true && props.BannerAds?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
    //                 await updateDoc(doc(db, "Banner", "Ads"), {
    //                     Ads: arrayRemove(item2),
    //                 })
    //             ));
    //             props.UserData !== null && props.UserData.StoreAdded === true && props.CategoryAds?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
    //                 await updateDoc(doc(db, "CategoryAds", "Ads"), {
    //                     Ads: arrayRemove(item2),
    //                 })
    //             ));
    //             props.UserData !== null && props.UserData.StoreAdded === true && props.StoriesData?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
    //                 await deleteDoc(doc(db, "Story", item2.storyID))
    //             ));
    //             props.UserData !== null && props.UserData.StoreAdded === true && props.VideosData?.filter(item1 => item1.storeID === props.UserData.storeID).map(async (item2, index) => (
    //                 await deleteDoc(doc(db, "Video", item2.videoID))
    //             ));
    //             props.UserData !== null && props.UserData.StoreAdded === true && props.Promoted.filter(item => item.Category === props.UserData.storeCategory)[0].Products.filter(item2 => item2.storeID === props.UserData.storeID).map(async (item3, index) => (
    //                 await updateDoc(doc(db, "Promoted", props.UserData.storeCategory), {
    //                     Products: arrayRemove(item3),
    //                 }).then(async (willdoThis) => {
    //                     await updateDoc(doc(db, "PromotedProductCount", "ProductCount"), {
    //                         Count: increment(-1),
    //                     })
    //                 })
    //             ));
    //             await deleteDoc(doc(db, "Stores", val.storeID));
    //         }
    //     });
    // };

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    return (
        <>
            {props.Stores.length === 0 &&
                <div className="NoSellersAvailable h-full w-full flex justify-center items-center text-center text-2xl font-black">
                    NO SELLERS FOUND!
                </div>
            }
            {props.Stores.filter(item => item.approved === false).length > 0 &&
                <div className="content-section">
                    <div className="content-section-title text-xl font-semibold">New Sellers</div>
                    <ul>
                        {props.Stores.filter(item => item.approved === false).map((item, index) => (
                            <li className="adobe-product flex flex-row justify-between" key={index}>
                                <div className="products flex gap-2 cursor-pointer">
                                    <img src={item.logo} alt="Logo" className="h-10 w-10 rounded-full" />
                                    {bottomBarVisible <= 768 ? <span className="font-bold">{truncate(item.storeName, 10)}</span> : <span className="font-bold">{item.storeName}</span>}
                                </div>
                                {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Role === 'Admin' && <button className="content-button status-button open" onClick={() => ApproveSeller(item)}>APPROVE</button>}
                                {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Role === 'Admin' && <button className="content-button status-button open" onClick={() => DeclineSeller(item)}>DECLINE</button>}
                            </li>
                        ))}
                    </ul>
                </div>
            }
            {props.Stores.filter(item => item.approved === true).length > 0 &&
                <div className="content-section">
                    <div className="content-section-title text-xl font-semibold">Sellers</div>
                    {/* <ul>
                        <li className="adobe-product flex flex-row justify-between">
                            <div className="products flex gap-2 cursor-pointer">
                                <span className="font-extrabold underline">STORE DETAILS</span>
                            </div>
                            <div className="products w-max cursor-pointer">
                                <span className="font-extrabold underline">OWNER NAME</span>
                            </div>
                            <div className="products w-max cursor-pointer">
                                <span className="font-extrabold underline">CATEGORY</span>
                            </div>
                        </li>
                    </ul> */}
                    <ul>
                        {props.Stores.filter(item => item.approved === true).map((item, index) => (
                            <li className="adobe-product flex flex-row justify-between" key={index}>
                                <div className="products flex gap-2 w-10 cursor-pointer">
                                    <img src={item.logo} alt="Logo" className="h-10 w-10 rounded-full" />
                                    {bottomBarVisible <= 768 ? <span className="font-bold w-10">{item.storeName}</span> : <span className="font-bold w-10">{item.storeName}</span>}
                                </div>
                                {bottomBarVisible >= 768 &&
                                    <div className="products w-max cursor-pointer">
                                        <span className="font-bold">{item.owner}</span>
                                    </div>
                                }
                                {bottomBarVisible >= 768 &&
                                    <div className="products w-max cursor-pointer">
                                        <span className="font-bold">{item.category}</span>
                                    </div>
                                }
                                {/* <button className="content-button status-button open">APPROVE</button> */}
                                {/* <button className="content-button status-button open" onClick={() => RemoveSeller(item)}>REMOVE SELLER</button> */}
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </>
    )
}

export default Sellers