import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'

const PendingPermission = (props) => {
    const { currentUser } = useContext(AuthContext);

    return (
        <div className='h-[90vh] w-[90vw] rounded-2xl flex justify-center items-center bg-[var(--search-bg)]'>
            {props.Users.length > 0 && props.Users?.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Permitted === false &&
                <div className="PermissionText text-2xl font-black">
                    {props.Users.length > 0 && props.Users.filter(item => item.EMail === currentUser.email).filter(item => item.AdminPanel === true)[0].Blocked === true ? 'ADMIN HAS DENIED YOUR REQUEST' : 'WAITING FOR PERMISSION FROM THE ADMIN'}
                </div>
            }
        </div>
    )
}

export default PendingPermission