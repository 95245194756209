/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingBar from 'react-top-loading-bar';
import TopBarProgress from 'react-topbar-progress-indicator';
import swal from '@sweetalert/with-react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db, storage } from '../firebase';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import gsap from 'gsap';
import { FileInput, Label } from 'flowbite-react';
import moment from 'moment';

const Auth = () => {
    // eslint-disable-next-line no-unused-vars
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progresspercent, setProgressPercent] = useState();
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);
    // eslint-disable-next-line no-unused-vars
    const [text, setText] = useState("");
    const [pfpFile, setPFPFile] = useState(null);
    const [form, setForm] = useState('Login');
    // eslint-disable-next-line no-unused-vars
    const [loginStyle, setLoginStyle] = useState('Email');
    // eslint-disable-next-line no-unused-vars
    const [signupStyle, setSignupStyle] = useState('Email');

    useEffect(() => {
        gsap.fromTo(".wrapper", { opacity: 0 }, { opacity: 1, duration: 2 });
    }, []);

    const HandleSignupEmail = async (e) => {
        const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
        if (pfpFile === null) {
            setLoading(false);
            e.preventDefault();
            swal("Profile Photo Required", '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
        } else {
            setLoading(true);
            e.preventDefault();
            const displayName = e.target[0].value;
            const EMail = e.target[1].value;
            const Password = e.target[2].value;
            const File = pfpFile;
            try {
                const res = await createUserWithEmailAndPassword(auth, EMail, Password);
                const storageRef = ref(storage, "Users/" + res.user.uid);
                const uploadTask = uploadBytesResumable(storageRef, File);
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        const roundedprogress = Math.round(progress);
                        setProgressPercent(roundedprogress);
                    },
                    (error) => {
                        setErr(true);
                        setLoading(false);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            // console.log('File available at', downloadURL);
                            await updateProfile(res.user, {
                                displayName,
                                photoURL: downloadURL,
                            });
                            await setDoc(doc(db, "Users", res.user.uid), {
                                uid: res.user.uid,
                                displayName: res.user.displayName,
                                EMail: res.user.email,
                                photoURL: downloadURL,
                                Provider: 'Email',
                                TimeCreated: currentTime,
                                TimeStamp: serverTimestamp(),
                                Role: "",
                                AdminPanel: true,
                                Permitted: false,
                                Blocked: false,
                            });
                            setLoading(false);
                            navigate("/PendingPermission")
                        }
                        );

                    });
            } catch (err) {
                setErr(true);
                // setErrorCode(err.code);
                setLoading(false);
                swal(<span className="text-red-500 uppercase font-extrabold">Something went wrong <br /> {err.code}</span>, '', 'error', {
                    buttons: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "bg-[#8c52ff]",
                            closeModal: true
                        }
                    }
                })
            }

        }
    }

    const handleImageChange = e => {
        const validExtensions = ["image/jpg", "image/png", "image/jpeg"]
        if (!validExtensions.includes(e.target.files[0].type)) {
            swal("File Format Not Supported", '', 'warning', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
            setText("");
            setLoading(false);
            setPFPFile(null);
            return false
        } else {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                swal("File Size Should Be Less Than 10 MB", '', 'warning', {
                    buttons: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            className: "bg-[#8c52ff]",
                            closeModal: true
                        }
                    }
                })
                setText("");
                setLoading(false);
                setPFPFile(null);
                return false
            } else {
                setText(e.target.files[0].name)
                setPFPFile(e.target.files[0])
            }
        }
    }

    const HandleLoginEmail = async (e) => {
        e.preventDefault()
        const EMail = e.target[0].value;
        const Password = e.target[1].value;

        try {
            setLoading(true);
            await signInWithEmailAndPassword(auth, EMail, Password);
            navigate("/")
        } catch (err) {
            setErr(true);
            // setErrorCode(err.code);
            swal(<span className="text-red-500 uppercase font-extrabold">Something went wrong <br /> {err.code}</span>, '', 'error', {
                buttons: {
                    confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "bg-[#8c52ff]",
                        closeModal: true
                    }
                }
            })
            setLoading(false);
        }
    }

    // const HandleGoogle = async (e) => {
    //     const currentTime = moment().format('hh:mm:ss DD MMMM YYYY');
    //     setLoading(true);
    //     signInWithPopup(auth, provider)
    //         .then(async (result) => {
    //             const user = result.user;
    //             const data = result._tokenResponse
    //             const uid = uuid()
    //             if (data.isNewUser) {
    //                 await setDoc(doc(db, "Users", user.uid), {
    //                     uid: user.uid,
    //                     displayName: user.displayName,
    //                     EMail: user.email,
    //                     photoURL: user.photoURL,
    //                     Provider: 'Google',
    //                     TimeCreated: currentTime,
    //                     TimeStamp: serverTimestamp(),
    //                     Role: "",
    //                     AdminPanel: true,
    //                     Permitted: false,
    //                     Blocked: false,
    //                 });
    //                 setLoading(false);
    //                 navigate('/');
    //             } else {
    //                 await setDoc(doc(db, "Users", uid), {
    //                     uid: uid,
    //                     displayName: user.displayName,
    //                     EMail: user.email,
    //                     photoURL: user.photoURL,
    //                     Provider: 'Google',
    //                     TimeCreated: currentTime,
    //                     TimeStamp: serverTimestamp(),
    //                     Role: "",
    //                     AdminPanel: true,
    //                     Permitted: false,
    //                     Blocked: false,
    //                 });
    //                 setLoading(false);
    //                 navigate('/');
    //             }
    //         }).catch((error) => {
    //             setLoading(false);
    //             if (error.code === "auth/account-exists-with-different-credential") {
    //                 // let pendingCred = error.credential;
    //                 // console.log(pendingCred)
    //                 swal('User Already Exists With A Different Provider', '', 'warning', {
    //                     buttons: {
    //                         confirm: {
    //                             text: "OK",
    //                             value: true,
    //                             visible: true,
    //                             className: "bg-[#8c52ff]",
    //                             closeModal: true
    //                         }
    //                     }
    //                 })
    //             } else {
    //                 swal(error.code, '', 'error', {
    //                     buttons: {
    //                         confirm: {
    //                             text: "OK",
    //                             value: true,
    //                             visible: true,
    //                             className: "bg-[#8c52ff]",
    //                             closeModal: true
    //                         }
    //                     }
    //                 })
    //             }
    //         });
    // }

    TopBarProgress.config({
        barThickness: 8
    });

    if (!loading) {
        if (currentUser) {
            navigate("/")
        }
    }

    useEffect(() => {
        localStorage.getItem('CurrentTabHome') === null && localStorage.setItem('CurrentTabHome', 'Sellers')
        localStorage.getItem('CurrentTabManage') === null && localStorage.setItem('CurrentTabManage', 'Cities')
        localStorage.getItem('CurrentTabSettings') === null && localStorage.setItem('CurrentTabSettings', 'Sellers')
    }, []);

    return (
        <>
            {form === 'Login' ?
                <div className="AuthenticationLoginForm">
                    {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
                    <section>
                        <div className="form-box">
                            <div className="form-value">
                                {loginStyle === 'Email' &&
                                    <form onSubmit={HandleLoginEmail} id="form">
                                        <span className='w-full pt-2 flex justify-start items-center'><img src="https://cdn-icons-png.freepik.com/512/13526/13526096.png" alt="Back Icon" className='h-8 w-8 cursor-pointer' onClick={() => navigate('/')} /></span>
                                        <h2>Login</h2>
                                        <div className="inputbox">
                                            <ion-icon name="mail-outline"></ion-icon>
                                            <input type="text" required id="logemail" className='border-none outline-none' />
                                            <label for="">Email</label>
                                        </div>
                                        <div className="inputbox">
                                            <ion-icon name="lock-closed-outline"></ion-icon>
                                            <input type="password" required id="logpass" className='border-none outline-none' />
                                            <label for="">Password</label>
                                        </div>
                                        <div className="forget">
                                            <label>
                                                <a className='cursor-pointer'>Forgot password?</a>
                                            </label>
                                        </div>
                                        {loading ? <button>Logging In</button> : <button>Log In</button>}
                                        <div className="OtherSignInMethods flex flex-col">
                                            {/* <div className="CommonLoginBox PhoneSignInBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => setLoginStyle('Phone')}>
                                                <img src="https://cdn-icons-png.freepik.com/512/3014/3014736.png" alt="Phone Login Icon" className='h-5 w-5' />
                                                <h1>Sign In Using Phone</h1>
                                            </div> */}
                                            {/* <div className="CommonLoginBox GoogleSignInBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => HandleGoogle()}>
                                                <img src="https://cdn-icons-png.freepik.com/512/2875/2875404.png" alt="Google Login Icon" className='h-5 w-5' />
                                                <h1>Sign In Using Google</h1>
                                            </div> */}
                                        </div>
                                        <div className="register">
                                            <p>Don't have a account ? <a className="cursor-pointer" onClick={() => { document.getElementById("form").reset(); setForm('Register') }}>Register</a></p>
                                        </div>
                                    </form>
                                }
                                {/* {loginStyle === 'Phone' &&
                                    <form onSubmit={HandleLoginPhone}>
                                        <span className='w-full pt-2 flex justify-start items-center'><img src="https://cdn-icons-png.freepik.com/512/13526/13526096.png" alt="Back Icon" className='h-8 w-8 cursor-pointer' onClick={() => navigate(-1)} /></span>
                                        <h2>Login</h2>
                                        <div className="inputbox">
                                            <ion-icon name="mail-outline"></ion-icon>
                                            <input type="tel" required id="logemail" className='border-none outline-none' />
                                            <label for="">Phone Number</label>
                                        </div>
                                        <div className="inputbox">
                                            <ion-icon name="lock-closed-outline"></ion-icon>
                                            <input type="password" required id="logpass" className='border-none outline-none' />
                                            <label for="">OTP</label>
                                        </div>
                                        <button>Log in</button>
                                        <div className="OtherSignInMethods flex flex-col">
                                            <div className="CommonLoginBox EmailSignInBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => setLoginStyle('Email')}>
                                                <img src="https://cdn-icons-png.freepik.com/512/3296/3296464.png" alt="Email Login Icon" className='h-5 w-5' />
                                                <h1>Sign In Using Email</h1>
                                            </div>
                                            <div className="CommonLoginBox GoogleSignInBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => HandleGoogle()}>
                                                <img src="https://cdn-icons-png.freepik.com/512/2875/2875404.png" alt="Google Login Icon" className='h-5 w-5' />
                                                <h1>Sign In Using Google</h1>
                                            </div>
                                        </div>
                                        <div className="register">
                                            <p>Don't have a account ? <a className="cursor-pointer" onClick={() => setForm('Register')}>Register</a></p>
                                        </div>
                                    </form>
                                } */}
                            </div>
                        </div>
                    </section>
                </div>
                :
                <div className="AuthenticationLoginForm">
                    {loading && <LoadingBar color='linear-gradient(45deg,#ffbc00,#00d0ff ,#ff0058)' shadow={true} progress={progresspercent} onLoaderFinished={() => setProgressPercent(0)} height={4} style={{ "border-radius": "44px" }} />}
                    <section>
                        <div className="form-box">
                            <div className="form-value">
                                {signupStyle === 'Email' &&
                                    <form onSubmit={HandleSignupEmail} id="form">
                                        <span className='w-full pt-2 flex justify-start items-center'><img src="https://cdn-icons-png.freepik.com/512/13526/13526096.png" alt="Back Icon" className='h-8 w-8 cursor-pointer' onClick={() => navigate('/')} /></span>
                                        <h2>Register</h2>
                                        <div className="inputbox">
                                            <ion-icon name="mail-outline"></ion-icon>
                                            <input type="text" required id="logname" className='border-none outline-none' />
                                            <label for="">Username</label>
                                        </div>
                                        <div className="inputbox">
                                            <ion-icon name="mail-outline"></ion-icon>
                                            <input type="text" required id="logemail" className='border-none outline-none' />
                                            <label for="">Email</label>
                                        </div>
                                        <div className="inputbox">
                                            <ion-icon name="lock-closed-outline"></ion-icon>
                                            <input type="password" required id="logpass" className='border-none outline-none' />
                                            <label for="">Password</label>
                                        </div>
                                        <div className="inputbox-file mb-2">
                                            <div className="mb-1 block">
                                                <Label htmlFor="file-upload" value="Upload Profile Picture" />
                                            </div>
                                            <FileInput id="file-upload" required onChange={handleImageChange} />
                                        </div>
                                        {loading ? <button disabled>Signing Up</button> : <button>Sign Up</button>}
                                        <div className="OtherSignUpMethods flex flex-col">
                                            {/* <div className="CommonLoginBox PhoneSignUpBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => setSignupStyle('Phone')}>
                                                <img src="https://cdn-icons-png.freepik.com/512/3014/3014736.png" alt="Phone Signup Icon" className='h-5 w-5' />
                                                <h1>Sign Up Using Phone</h1>
                                            </div> */}
                                            {/* <div className="CommonLoginBox GoogleSignUpBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => HandleGoogle()}>
                                                <img src="https://cdn-icons-png.freepik.com/512/2875/2875404.png" alt="Google Signup Icon" className='h-5 w-5' />
                                                <h1>Sign Up Using Google</h1>
                                            </div> */}
                                        </div>
                                        <div className="register">
                                            <p>Already a user ? <a className="cursor-pointer" onClick={() => { document.getElementById("form").reset(); setForm('Login') }}>Login</a></p>
                                        </div>
                                    </form>
                                }
                                {/* {signupStyle === 'Phone' &&
                                    <form onSubmit={HandleSignupPhone}>
                                        <span className='w-full pt-2 flex justify-start items-center'><img src="https://cdn-icons-png.freepik.com/512/13526/13526096.png" alt="Back Icon" className='h-8 w-8 cursor-pointer' onClick={() => navigate(-1)} /></span>
                                        <h2>Register</h2>
                                        <div className="inputbox">
                                            <ion-icon name="mail-outline"></ion-icon>
                                            <input type="tel" required id="logemail" className='border-none outline-none' />
                                            <label for="">Phone Number</label>
                                        </div>
                                        <div className="inputbox">
                                            <ion-icon name="lock-closed-outline"></ion-icon>
                                            <input type="password" required id="logpass" className='border-none outline-none' />
                                            <label for="">OTP</label>
                                        </div>
                                        <button>Sign Up</button>
                                        <div className="OtherSignUpMethods flex flex-col">
                                            <div className="CommonLoginBox EmailSignUpBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => setSignupStyle('Email')}>
                                                <img src="https://cdn-icons-png.freepik.com/512/3296/3296464.png" alt="Email Signup Icon" className='h-5 w-5' />
                                                <h1>Sign Up Using Email</h1>
                                            </div>
                                            <div className="CommonLoginBox GoogleSignUpBox flex flex-row gap-5 cursor-pointer justify-center items-center rounded-lg p-2 w-full mt-5" onClick={() => HandleGoogle()}>
                                                <img src="https://cdn-icons-png.freepik.com/512/2875/2875404.png" alt="Google Signup Icon" className='h-5 w-5' />
                                                <h1>Sign Up Using Google</h1>
                                            </div>
                                        </div>
                                        <div className="register">
                                            <p>Already a user ? <a className="cursor-pointer" onClick={() => setForm('Login')}>Login</a></p>
                                        </div>
                                    </form>
                                } */}
                            </div>
                        </div>
                    </section>
                </div>
            }
        </>
    )
}

export default Auth