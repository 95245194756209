/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom';
import Cities from '../components/Cities';
import Categories from '../components/Categories';
import Admin from '../components/Admin';
// import Export from '../components/Export';
import { Dropdown } from 'flowbite-react';
import Ads from '../components/Ads';

const Manage = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const [currentTabManage, setCurrentTabManage] = useState(localStorage.getItem('CurrentTabManage'));
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    return (
        <>
            <div className="app">
                <div className="header">
                    <div className="header-menu">
                        <Link className={`menu-link ${props.Stores.filter(item => item.approved === false).length > 0 && 'notify'}`} to='/' >HOME</Link>
                        <Link className={`menu-link is-active ${props.Users.length > 0 && props.Users?.filter(item => item.AdminPanel === true).filter(item => item.Permitted === false).filter(item => item.Blocked === false).length > 0 && 'notify'}`} to='/Manage' >MANAGE</Link>
                        <Link className="menu-link" to='/Settings' >SETTINGS</Link>
                    </div>
                    <div className="header-profile">
                        {currentUser && <img className="profile-img cursor-pointer" src={currentUser.photoURL} alt="" />}
                    </div>
                </div>
                <div className="wrapper">
                    <div className="main-container">
                        <div className="main-header bg-[var(--theme-bg-color)]">
                            <div className="header-menu menu-2 justify-evenly">
                                <span className={`main-header-link ${currentTabManage === 'Cities' && 'is-active'}`} onClick={() => { localStorage.setItem('CurrentTabManage', 'Cities'); setCurrentTabManage('Cities') }}>CITIES</span>
                                <span className={`main-header-link ${currentTabManage === 'Categories' && 'is-active'}`} onClick={() => { localStorage.setItem('CurrentTabManage', 'Categories'); setCurrentTabManage('Categories') }}>CATEGORIES</span>
                                <span className={`main-header-link ${currentTabManage === 'Admin' && 'is-active'} ${props.Users.length > 0 && props.Users?.filter(item => item.AdminPanel === true).filter(item => item.Permitted === false).filter(item => item.Blocked === false).length > 0 && 'notify'}`} onClick={() => { localStorage.setItem('CurrentTabManage', 'Admin'); setCurrentTabManage('Admin') }}>ADMIN</span>
                                <span className={`main-header-link ${currentTabManage === 'Ads' && 'is-active'}`} onClick={() => { localStorage.setItem('CurrentTabManage', 'Ads'); setCurrentTabManage('Ads') }}>ADS</span>
                                {/* <span className={`main-header-link ${currentTabManage === 'Export' && 'is-active'}`} onClick={() => { localStorage.setItem('CurrentTabManage', 'Export'); setCurrentTabManage('Export') }}>EXPORT</span> */}
                            </div>
                        </div>
                        <div className="content-wrapper">
                            {currentTabManage === 'Cities' && <Cities Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Locations={props.Locations} Category={props.Category} Categories={props.Categories} Users={props.Users} />}
                            {currentTabManage === 'Categories' && <Categories Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Locations={props.Locations} Category={props.Category} Categories={props.Categories} Users={props.Users} />}
                            {currentTabManage === 'Admin' && <Admin Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Locations={props.Locations} Category={props.Category} Categories={props.Categories} Users={props.Users} />}
                            {currentTabManage === 'Ads' && <Ads Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Locations={props.Locations} Category={props.Category} Categories={props.Categories} Users={props.Users} />}
                            {/* {currentTabManage === 'Export' && <Export Stores={props.Stores} Shops={props.Shops} States={props.States} Cities={props.Cities} Locations={props.Locations} Category={props.Category} Categories={props.Categories} Users={props.Users} />} */}
                        </div>
                    </div>
                </div>
                <div className="overlay-app"></div>
            </div>
        </>
    )
}

export default Manage