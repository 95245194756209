/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Shops = (props) => {
    const { currentUser } = useContext(AuthContext);
    const [bottomBarVisible, setBottomBarVisible] = useState(window.screen.width);
    const navigate = useNavigate();
    const [storeState, setStoreState] = useState('');
    const [storeCity, setStoreCity] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setBottomBarVisible(window.screen.width)
        }, 100);
        return () => clearInterval(interval);
    }, []);

    const handleChangeStoreState = (event) => {
        setStoreState(event.target.value);
    };

    const handleChangeStoreCity = (event) => {
        setStoreCity(event.target.value);
    };

    const truncate = (str, length) => {
        if (str.length > length) {
            return str.slice(0, length) + "...";
        } else {
            return str;
        }
    }

    return (
        <>
            {props.Shops.length > 0 ?
                <div className="content-section-new flex flex-col mt-5 h-max min-w-[95%]">
                    <div className="content-section-title text-xl font-medium">Filter</div>
                    <div class="apps-card-sell gap-5 w-full h-max p-5 flex flex-wrap justify-evenly items-center rounded-lg bg-[var(--theme-bg-color)]">
                        <div class="app-card-sell">
                            <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                <span className="w-full flex text-start">STATE</span>
                                <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR STATE</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={storeState}
                                        onChange={handleChangeStoreState}
                                        label="CHOOSE YOUR STATE"
                                        className='w-60'
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {props.States.length > 0 && props.States?.sort((a, b) => a.localeCompare(b)).map(city => (
                                            <MenuItem value={city}>{city}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div class="app-card-sell">
                            <div className="shopCategoryInput flex justify-start items-center mt-5 flex-col gap-2">
                                <span className="w-full flex text-start">CITY</span>
                                <FormControl variant="filled" sx={{ m: 1, minWidth: '100%' }}>
                                    <InputLabel id="demo-simple-select-standard-label">CHOOSE YOUR CITY</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={storeCity}
                                        onChange={handleChangeStoreCity}
                                        label="CHOOSE YOUR CITY"
                                        className='w-60'
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {storeState !== '' && props.Cities.length > 0 && props.Cities?.filter((item, idx) => item.state_name === storeState).sort((a, b) => a.name.localeCompare(b.name)).map(city => (
                                            <MenuItem value={city.name}>{city.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="content-section-title text-xl font-semibold mt-10">Shops</div>
                    {storeCity !== '' ?
                        <>
                            {props.Shops.filter(item => item.storeCity === storeCity).length > 0 ?
                                <div className="apps-card">
                                    {/* {shops?.filter((item, idx) => idx <= 7).sort((a, b) => a.storeName.localeCompare(b.storeName)).map(shop => ( */}
                                    {props.Shops.filter(item => item.storeCity === storeCity).map(shop => (
                                        <div className="app-card-shops flex-col cursor-pointer p-5 min-w-72 flex justify-center items-center">
                                            <div className="app-card-store-logo flex w-full justify-center items-center">
                                                <img src={shop?.logo} alt="logo" className='h-14 w-14 rounded-full' />
                                            </div>
                                            <div className="app-card__subtext w-full flex text-center justify-center items-center font-extrabold">
                                                <span className='font-extrabold text-xl'>{shop.storeName}</span>
                                            </div>
                                            <div className="app-card-buttons flex justify-center items-center text-center w-full">
                                                <div className="productShowOne flex flex-row w-full justify-between items-center text-center pt-2 pb-2 pl-5 pr-5 rounded-xl">
                                                    <img src={shop.Products.productImage[0]} alt="" className='h-14 w-14 rounded-lg' />
                                                    <span className='font-semibold text-lg'>{truncate(shop.Products.productName, 15)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                :
                                <>
                                    <div className="w-full h-full justify-center items-center text-center text-xl font-bold">
                                        NO SHOPS FOUND IN {storeCity}
                                    </div>
                                </>
                            }
                        </>
                        :
                        <div className="apps-card">
                            {/* {shops?.filter((item, idx) => idx <= 7).sort((a, b) => a.storeName.localeCompare(b.storeName)).map(shop => ( */}
                            {props.Shops.map(shop => (
                                <div className="app-card-shops flex-col cursor-pointer p-5 min-w-80 max-w-80 flex justify-center items-center" onClick={() => navigate(`/Store/${shop?.storeID}`)}>
                                    <div className="app-card-store-logo flex w-full justify-center items-center">
                                        <img src={shop?.logo} alt="logo" className='h-20 w-20 rounded-full' />
                                    </div>
                                    <div className="app-card__subtext w-full flex text-center justify-center items-center font-extrabold">
                                        <span className='font-extrabold text-xl'>{shop.storeName}</span>
                                    </div>
                                    <div className="app-card-buttons flex justify-center items-center text-center w-full">
                                        <div className="productShowOne flex flex-row w-full gap-2 justify-between items-center text-center pt-2 pb-2 pl-5 pr-5 rounded-xl">
                                            <img src={shop.Products.productImage[0]} alt="" className='h-14 w-14 rounded-lg' />
                                            <span className='font-medium text-lg flex flex-grow'>{truncate(shop.Products.productName, 25)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                :
                <div className='w-full h-full flex justify-center items-center text-center text-2xl font-black'>
                    NO SHOPS FOUND!
                </div>
            }
        </>
    )
}

export default Shops